import superagent from 'superagent';
import { omit } from 'underscore';

const methods = ['get', 'post', 'put', 'patch', 'del'];

function formatUrl(path) {
  return path;
}

class ApiClient {
  constructor(req) {
    this.bearerToken = undefined;

    methods.forEach(
      method =>
        (this[method] = (path, { query, data, custom } = {}) => {
          const request = superagent[method](formatUrl(path));

          let promise = new Promise((resolve, reject) => {
            if (query) {
              request.query(query);
            }

            if (data) {
              request.send(data);
            }

            if (!custom) {
              request.set('Content-Type', 'application/json');
              if (this.bearerToken) {
                request.set('Authorization', `Bearer ${this.bearerToken}`);
              }
            }

            request.end((err, { body, text, header } = {}) => {
              err ? reject(body || text || err) : resolve(body || text);
            });
          });

          promise.abort = () => {
            request.abort();
          };

          return promise;
        })
    );
  }

  setBearerToken(bearerToken) {
    this.bearerToken = bearerToken;
  }
}

const api = new ApiClient();

export default function clientMiddleware() {
  return ({ dispatch, getState }) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      if (action instanceof Promise) {
        return action;
      }

      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      const actionPromise = promise(api);
      actionPromise
        .then(
          result => next({ ...rest, payload: result, result, type: SUCCESS }),
          error => next({ ...rest, payload: error, error, type: FAILURE })
        )
        .catch(error => {
          console.error('MIDDLEWARE ERROR:', error);
          next({ ...rest, payload: error, error, type: FAILURE });
        });

      return actionPromise;
    };
  };
}

export function formatError(error) {
  if (error && typeof error.error === 'object') {
    error = error.error;
  }

  return error
    ? error.error || error.message || error.error_message || error.name || error
    : error;
}

export function cleanQuery(query) {
  return omit(query, i => i === '' || i === undefined || i === null);
}

export { api };

import React from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { length, required, url, format } from 'redux-form-validators';

import { withStyles } from '@material-ui/core/styles';
import TextFieldBase from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import withField from '../../decorators/with-field';
import { useDispatch } from 'react-redux';
import { generateId } from '../../utils';

const TextField = withField(TextFieldBase);

const styles = theme => ({
  root: {},
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
});

function AccessKeyField({ initialValues, ...props }) {
  return (
    <>
      <TextField {...props} />
      <Button
        onClick={() => {
          props.input.onChange(initialValues.accountId);
        }}
        title="Revert Access Key"
        color="primary"
        variant="contained"
        disabled={
          props.disabled || props.input.value === initialValues.accountId
        }
      >
        Revert Access Key
      </Button>
    </>
  );
}

const SettingsForm = ({
  disabled,
  classes = {},
  hideSubmit,
  onRegenerateSecret,
  ...props
}) => {
  const { valid, dirty, isSubmitting } = props;
  const dispatch = useDispatch();
  return (
    <form className={classes.root} onSubmit={props.handleSubmit}>
      <Field
        name="accessKeyId"
        component={AccessKeyField}
        initialValues={props.initialValues}
        label="Access Key ID:"
        fullWidth
        margin="normal"
        validate={[required(), length({ min: 1, max: 100 })]}
        helperText="Defaults to your actual Amazon ID but can be changed if wanted."
      />
      <Field
        name="endpointURL"
        component={TextField}
        label="Endpoint URL:"
        fullWidth
        margin="normal"
        helperText="This should be a url that can reach inside of your network and call the client side portion."
        validate={[
          required(),
          length({ min: 1, max: 2083 }),
          url({ protocols: ['http', 'https'] })
        ]}
      />
      <Field
        name="sharedSecret"
        component={TextField}
        label="Shared Secret:"
        fullWidth
        margin="normal"
        helperText="This needs to be kept private and is used to sign and validate JWT tokens between the server and the client. Only visible on initial generation."
        placeholder="Enter a new secret here or leave blank"
        validate={[
          required(),
          length({ min: 10, max: 1000 }),
          format({
            with: /^(|[A-F0-9]{32})$/i,
            message:
              'Needs to be empty (preserve current secret), or a 32 character hex string'
          })
        ]}
      />
      <Button
        onClick={() => {
          const newSecret = generateId(50).substring(0, 32);
          dispatch(change(props.form, 'sharedSecret', newSecret));
        }}
        title="Regenerate Secret"
        color="primary"
        variant="contained"
        disabled={props.disabled}
      >
        Regenerate Access Key
      </Button>
      {!hideSubmit && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            disabled={disabled || (dirty && (!valid || isSubmitting))}
          >
            Save
          </Button>
        </div>
      )}
    </form>
  );
};

export default reduxForm({
  form: 'form-settings'
})(withStyles(styles, { withTheme: true })(SettingsForm));

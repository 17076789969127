import React from 'react';
import { Link } from 'react-router-dom';

export default function HomePage() {
  return (
    <>
      <h1>Welcome to Alexa Chromecast!</h1>
      <h2>First time here?</h2>
      <p>
        You'll need to goto <Link to="/settings">Settings</Link> in order to
        configure the connection between Alexa and the client code from inside
        your network.
      </p>
      <h2>Full Installation Steps:</h2>
      <ol>
        <li>
          <h3>Add the "Unofficial Chromecast w/ Plex" App to Alexa</h3>
          <p>
            <a
              href="https://alexa.amazon.com/spa/index.html#skills/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Here to search and enable this skill.
            </a>
          </p>
        </li>
        <li>
          <h3>Link Account</h3>
          <p>
            Enabling the skill should lead you to an account linking page but
            otherwise go{' '}
            <a
              href="https://alexa.amazon.com/spa/index.html#skills/your-skills"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            and look for "Account linking required". You'll need to login to
            Amazon for this step as that is how the integration tracks who is
            using it.
          </p>
        </li>
        <li>
          <h3>Setup Client App</h3>
          <p>
            The "Unofficial Chromecast w/ Plex" requires an app to run inside
            your network which the server and Alexa will communicate with using
            a "Shared Secret".
          </p>
          <p>
            Steps for this are a WIP but you'll need to setup a DDNS or if you
            have a stable IP Address you can just setup a Port Forward.
          </p>
        </li>
        <li>
          <h3>Enter Settings</h3>
          <p>
            The "Unofficial Chromecast w/ Plex" requires information on how to
            contact your local network. The values you enter here should reflect
            your Client App settings.
          </p>
        </li>
        <li>
          <h3>Test Connection</h3>
          <p>
            Ask Alexa to "Discover Devices" and it should discover your
            chromecasts if your Client App is configured properly, and your
            Settings are configured to point properly to inside your network.
          </p>
          <p>Ask Alexa to "Turn On CHROMECAST_NAME"</p>
        </li>
        <li>
          <h3>Optional: Link a Default Device</h3>
          <p>
            Each Chromecast found can be linked to an Alexa device so that the
            default action applies to the Chromecast selected.
          </p>
          <p>
            By default if no linking has occurred, asking Alexa to "Play TV"
            will use the last Chromecast accessed or error out if none have been
            accessed recently.
          </p>
          <p>
            If linked, asking Alexa to "Play TV" will use the last Chromecast
            accessed or the default device that you linked to the given Alexa.
          </p>
        </li>
      </ol>
    </>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit, isDirty, isSubmitting, isValid } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import { LOAD_USER } from '../../ducks/Auth';

import SettingsForm from './SettingsForm';

import {
  SAVE_USER_SETTINGS as saveSettings,
  REGENERATE_ACCESS_KEY as regenerateAccessKey
} from '../../ducks/Auth';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  form: {
    width: '100%'
  }
});

class Settings extends Component {
  render() {
    const { loaded, loggedIn } = this.props;
    const { classes, isLoading, record } = this.props;
    const { isValid, isDirty, isSubmitting, doSubmit } = this.props;
    const isDisabled = isLoading || isSubmitting;
    return !loaded || !loggedIn ? (
      <div className={classes.root}>
        <h1>Must be logged in to continue</h1>
      </div>
    ) : (
      <div className={classes.root}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={8} md={6}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="inherit">Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SettingsForm
                  hideSubmit
                  classes={{
                    root: classes.form
                  }}
                  enableReinitialize
                  initialValues={record}
                  onSubmit={this.handleSubmit}
                  disabled={isDisabled}
                />
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !isValid || !isDirty || isSubmitting}
                  onClick={() => doSubmit('form-settings')}
                >
                  Save
                </Button>
              </AccordionActions>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="inherit">Help</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div>
                    <Typography variant="h6">Access Key ID</Typography>
                    <Typography paragraph variant="body2">
                      The <b>Access Key ID</b> is a value provided by the
                      authentication of your amazon account via login. This
                      should match the same ID that the Alexa Skill will return
                      and allow Alexa Chromecast to lookup your account.
                    </Typography>
                    <Typography paragraph variant="body2">
                      You will never have to set this value.
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="h6">Endpoint URL</Typography>
                    <Typography paragraph variant="body2">
                      The <b>Endpoint URL</b> is the URL that will tunnel into
                      your network and reach the <b>/askhomeskill</b> endpoint
                      in the{' '}
                      <a href="#WIP">
                        <b>client software</b>
                      </a>
                      .
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="h6">Shared Secret</Typography>
                    <Typography paragraph variant="body2">
                      The <b>Shared Secret</b> is how all communication between
                      the server and the client are verified. Once provided to
                      you, save the secret and input it into the client
                      configuration.
                    </Typography>
                    <Typography paragraph variant="body2">
                      <b>TIP</b>: You can have multiple amazon accounts use the
                      same endpoint by using the same shared secret.
                    </Typography>
                    <Typography paragraph variant="body2">
                      <b>
                        This value is never redisplayed after creation or
                        saving.
                      </b>
                    </Typography>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    );
  }

  handleSubmit = (values, dispatch, props) => {
    if (values.sharedSecret === '') {
      values = { ...values, sharedSecret: undefined };
    }
    return this.props.saveSettings(values);
  };

  componentDidMount() {
    const { loadUser, loaded, loading } = this.props;

    if (!loaded && !loading) {
      loadUser();
    }
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(
    (state, props) => {
      const { loggedIn, loading, loaded, loadError } = state.Auth.toJS();
      return {
        loggedIn,
        loading,
        loaded,
        loadError,
        record: state.Auth.getIn(['user', 'settings']).toJS(),
        isDirty: isDirty('form-settings')(state),
        isLoading: state.Auth.getIn(['loading']),
        isValid: isValid('form-settings')(state),
        isSubmitting: isSubmitting('form-settings')(state)
      };
    },
    {
      doSubmit: submit,
      regenerateAccessKey,
      saveSettings,
      loadUser: LOAD_USER
    }
  )(Settings)
);

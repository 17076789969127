import { fromJS, Record } from 'immutable';
import { createDuck } from 'redux-duck';
import { api } from '../middleware/client';

const SettingsRecord = Record({
  endpointURL: '',
  sharedSecret: '',
  accessKeyId: '',
  accountId: ''
});

const UserRecord = Record({
  userId: '',
  token: '',
  type: '',
  scope: 'profile',
  expires: '',
  settings: new SettingsRecord()
});

const duck = createDuck('Auth', 'AlexaChromecast');
const LOAD_USER_TYPE = duck.defineType('LOAD');
const LOAD_USER_SUCCESS_TYPE = duck.defineType('LOAD_SUCCESS');
const LOAD_USER_FAIL_TYPE = duck.defineType('LOAD_FAIL');
const USER_LOGOUT_TYPE = duck.defineType('USER_LOGOUT');
const LOAD_USER_SETTINGS_TYPE = duck.defineType('LOAD_SETTINGS');
const LOAD_USER_SETTINGS_SUCCESS_TYPE = duck.defineType(
  'LOAD_SETTINGS_SUCCESS'
);
const LOAD_USER_SETTINGS_FAIL_TYPE = duck.defineType('LOAD_SETTINGS_FAIL');
const SAVE_USER_SETTINGS_TYPE = duck.defineType('SAVE_SETTINGS');
const SAVE_USER_SETTINGS_SUCCESS_TYPE = duck.defineType(
  'SAVE_SETTINGS_SUCCESS'
);
const SAVE_USER_SETTINGS_FAIL_TYPE = duck.defineType('SAVE_SETTINGS_FAIL');

const REGENERATE_ACCESS_KEY_TYPE = duck.defineType('REGENERATE_ACCESS_KEY');
const REGENERATE_ACCESS_KEY_SUCCESS_TYPE = duck.defineType(
  'REGENERATE_ACCESS_KEY_SUCCESS'
);
const REGENERATE_ACCESS_KEY_FAIL_TYPE = duck.defineType(
  'REGENERATE_ACCESS_KEY_FAIL'
);

const LOAD_USER = duck.createAction(LOAD_USER_TYPE);
const LOAD_USER_SUCCESS = duck.createAction(LOAD_USER_SUCCESS_TYPE);
const LOAD_USER_FAIL = duck.createAction(LOAD_USER_FAIL_TYPE);
const USER_LOGOUT = duck.createAction(USER_LOGOUT_TYPE);

const initialState = fromJS({
  user: new UserRecord({}),
  loggedIn: false,
  loading: false,
  loaded: false,
  loadError: undefined,
  saving: false,
  saveError: undefined
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_TYPE:
    case LOAD_USER_SETTINGS_TYPE:
    case REGENERATE_ACCESS_KEY_TYPE:
      state = state.merge({
        loading: true,
        loadError: undefined
      });
      break;
    case LOAD_USER_SUCCESS_TYPE:
      state = state.mergeDeep({
        loaded: true,
        loading: false,
        loggedIn: !!action.payload.access_token,
        user: {
          token: action.payload.access_token,
          scope: action.payload.scope,
          type: action.payload.token_type,
          expires: action.payload.expires_in
        }
      });
      api.setBearerToken(action.payload.access_token);
      break;
    case LOAD_USER_SETTINGS_SUCCESS_TYPE:
    case SAVE_USER_SETTINGS_SUCCESS_TYPE:
      state = state.merge({
        loading: false,
        saving: false
      });
      state = state.mergeIn(
        ['user', 'settings'],
        new SettingsRecord(action.payload.data)
      );
      break;
    case REGENERATE_ACCESS_KEY_SUCCESS_TYPE:
      state = state.setIn(['loading'], false);
      state = state.mergeIn(['user', 'settings'], action.payload.data);
      break;
    case LOAD_USER_FAIL_TYPE:
    case LOAD_USER_SETTINGS_FAIL_TYPE:
    case REGENERATE_ACCESS_KEY_FAIL_TYPE:
      state = state.merge({
        loading: false,
        loadError: action.payload
      });
      break;
    case USER_LOGOUT_TYPE:
      state = state.merge({
        loaded: false,
        user: new UserRecord({})
      });
      break;
    default:
      break;
  }

  return state;
}

const amazonLogin = new Promise((resolve, reject) => {
  window.onAmazonLoginReady = function () {
    window.amazon.Login.setClientId(process.env.REACT_APP_LOGIN_CLIENT_ID);
    resolve(window.amazon);
  };
});

(function (d) {
  var a = d.createElement('script');
  a.type = 'text/javascript';
  a.async = true;
  a.id = 'amazon-login-sdk';
  a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
  d.getElementById('amazon-root').appendChild(a);
})(document);

function REGENERATE_ACCESS_KEY() {
  return (dispatch, getState) => {
    dispatch({
      types: [
        REGENERATE_ACCESS_KEY_TYPE,
        REGENERATE_ACCESS_KEY_SUCCESS_TYPE,
        REGENERATE_ACCESS_KEY_FAIL_TYPE
      ],
      promise: client =>
        client.post('/api/accesskey').then(function (response) {
          return response;
        })
    });
  };
}

function LOAD_USER_ACTION() {
  return (dispatch, getState) => {
    return amazonLogin.then(amazon => {
      const options = {
        scope: process.env.REACT_APP_LOGIN_SCOPE,
        popup: true,
        response_type: 'token',
        interactive: 'auto'
      };

      dispatch(LOAD_USER(options));

      amazon.Login.authorize(options, (response = {}) => {
        const { error } = response;
        if (!error) {
          dispatch(LOAD_USER_SUCCESS(response));
          dispatch(LOAD_USER_SETTINGS(response));
        } else {
          dispatch(LOAD_USER_FAIL(response));
        }
      });
    });
  };
}

function LOAD_USER_SETTINGS() {
  return (dispatch, getState) => {
    dispatch({
      types: [
        LOAD_USER_SETTINGS_TYPE,
        LOAD_USER_SETTINGS_SUCCESS_TYPE,
        LOAD_USER_SETTINGS_FAIL_TYPE
      ],
      promise: client =>
        client.get('/api/settings').then(function (response) {
          return response;
        })
    });
  };
}

function SAVE_USER_SETTINGS(data) {
  return {
    types: [
      SAVE_USER_SETTINGS_TYPE,
      SAVE_USER_SETTINGS_SUCCESS_TYPE,
      SAVE_USER_SETTINGS_FAIL_TYPE
    ],
    promise: client =>
      client
        .post('/api/settings', {
          data
        })
        .then(function (response) {
          return response;
        })
  };
}

function LOGOUT_USER() {
  return (dispatch, getState) => {
    return amazonLogin.then(amazon => {
      amazon.Login.logout();
      dispatch(USER_LOGOUT());
    });
  };
}

export {
  LOAD_USER_ACTION as LOAD_USER,
  LOGOUT_USER,
  REGENERATE_ACCESS_KEY,
  SAVE_USER_SETTINGS
};
export default reducer;

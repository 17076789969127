// taken from https://stackoverflow.com/a/27747377/100658
// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
export function dec2hex(dec) {
  return dec < 10 ? '0' + String(dec) : dec.toString(16);
}

// taken from https://stackoverflow.com/a/27747377/100658
// generateId :: Integer -> String
export function generateId(len) {
  const crypto = window.crypto || window.msCrypto;
  const arr = new Uint8Array((len || 40) / 2);
  crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('').toUpperCase();
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Settings from './containers/Settings/Settings';
import TermsOfUse from './containers/TermsOfUse';
import PrivacyPolicy from './containers/PrivacyPolicy';

import { LOAD_USER, LOGOUT_USER } from './ducks/Auth';
import HomePage from './containers/Home';
import { Container } from '@material-ui/core';

const styles = theme => ({
  body: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

const App = props => {
  const [menuEl, setMenuEl] = useState();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const { classes, loadUser, loaded, loggedIn, logoutUser } = props;
  return (
    <Router>
      <div className="App">
        <AppBar className={classes.header} position="static">
          <Toolbar>
            <IconButton
              ref={setMenuEl}
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => setMenuOpen(value => !value)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="inherit"
              color="inherit"
              className={classes.grow}
            >
              <Menu
                anchorEl={menuEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={isMenuOpen}
                onClose={() => setMenuOpen(false)}
              >
                <MenuItem component={Link} to="/">
                  Home
                </MenuItem>
                <MenuItem component={Link} to="/settings">
                  Settings
                </MenuItem>
                <MenuItem component={Link} to="/privacy_policy">
                  Privacy Policy
                </MenuItem>
                <MenuItem component={Link} to="/terms_of_use">
                  Terms of Use
                </MenuItem>
              </Menu>
              Alexa Chromecast
            </Typography>
            {loaded && (
              <Button color="inherit" onClick={logoutUser}>
                Logout
              </Button>
            )}
            {!loaded && !loggedIn && (
              <Button color="inherit" onClick={loadUser}>
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>

        <Switch>
          <Route path="/privacy_policy">
            <Container maxWidth="md" className={classes.body}>
              <PrivacyPolicy />
            </Container>
          </Route>
          <Route path="/terms_of_use">
            <Container maxWidth="md" className={classes.body}>
              <TermsOfUse />
            </Container>
          </Route>
          <Route path="/settings">
            <Container maxWidth="xl" className={classes.body}>
              <Settings />
            </Container>
          </Route>
          <Route>
            <Container maxWidth="md" className={classes.body}>
              <HomePage />
            </Container>
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  connect(
    (state, props) => {
      const { loggedIn, loading, loaded, loadError } = state.Auth.toJS();
      return {
        loggedIn,
        loading,
        loaded,
        loadError
      };
    },
    {
      loadUser: LOAD_USER,
      logoutUser: LOGOUT_USER
    }
  )(App)
);

import { fromJS, List, Record } from 'immutable';
import { createDuck } from 'redux-duck';

const DeviceRecord = Record({
  id: '',
  name: '',
  certificate: ''
});

const duck = createDuck('Device', 'AlexaChromecast');
const LOAD_DEVICES_TYPE = duck.defineType('LOAD');
const LOAD_DEVICES_SUCCESS_TYPE = duck.defineType('LOAD_SUCCESS');
const LOAD_DEVICES_FAIL_TYPE = duck.defineType('LOAD_FAIL');

const initialState = fromJS({
  list: {
    loading: false,
    loaded: false,
    loadError: undefined,
    results: new List()
  }
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DEVICES_TYPE:
      state = state.mergeIn(['list'], {
        loading: true,
        loadError: undefined
      });
      break;
    case LOAD_DEVICES_SUCCESS_TYPE:
      state = state.mergeIn(['list'], {
        loaded: true,
        loading: false,
        results: action.payload.map(device => new DeviceRecord(device))
      });
      break;
    case LOAD_DEVICES_FAIL_TYPE:
      state = state.mergeIn(['list'], {
        loading: false,
        loadError: action.payload
      });
      break;
    default:
      break;
  }

  return state;
}

function LOAD_DEVICES() {
  return (dispatch, getState) => {
    const url = '/api/devices';
    dispatch({
      types: [
        LOAD_DEVICES_TYPE,
        LOAD_DEVICES_SUCCESS_TYPE,
        LOAD_DEVICES_FAIL_TYPE
      ],
      promise: client =>
        client.get(url).then(function (response) {
          return response;
        })
    });
  };
}

export { LOAD_DEVICES };
export default reducer;

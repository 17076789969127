import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

let withField = Cmp => {
  return props => {
    const { blockBlur, input, meta, ...cmpProps } = props;
    const { onBlur, ...inputProps } = input;
    const error = meta.error;
    return (
      <React.Fragment>
        <Cmp
          {...(blockBlur ? inputProps : input)}
          {...cmpProps}
          error={!!error}
          FormHelperTextProps={error ? { error: false } : undefined}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </React.Fragment>
    );
  };
};

export default withField;
